import React from "react"

const MobileMiddleStep = ({ classes, children }) => (
  <div className={classes}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="226"
      height="158"
      viewBox="0 0 226 158"
      className={classes}
    >
      <path
        d="M215.33,17.64 L131.64,49.24 C121.58,53.04 107.32,53.08 97.16,49.34 L11.93,19.24 C5.16,16.75 0,4.05 0,0 L0,0 L0,82.42 L0,82.42 C0,88.14 0.06,93.86 0,99.59 L0.2,112.74 C0.2,116.74 4.2,120.59 10.92,123.08 L96.92,154.71 C107.06,158.43 121.28,158.39 131.32,154.6 L214.82,123.08 C220.45,120.95 224.05,117.89 224.99,114.56 L224.99,0 C223.57,2.81 220.2,15.8 215.33,17.64 Z"
        id="Path"
      ></path>
    </svg>
    {children}
  </div>
)

export default MobileMiddleStep
