import React, { useRef, useEffect, useState } from "react"

import classNames from "classnames"
import { ScrollTrigger, gsap } from "gsap/all"

import { FOOTNOTE_SYMBOLS } from "constants/Site"
import { useWindowSize } from "hooks/useWindowSize"

import Slide1 from "images/palisade-card-c-slide-1.svg"
import Slide2 from "images/palisade-card-c-slide-2.svg"
import Slide3 from "images/palisade-card-c-slide-3.png"
import Slide4 from "images/palisade-card-c-slide-4.png"

import Info1 from "images/palisade-card-c-1.svg"
import Info2 from "images/palisade-card-c-2.svg"
import Info3 from "images/palisade-card-c-3.svg"
import Info4 from "images/palisade-card-c-4.svg"

import styles from "./PalisadeStudyDesign.module.scss"

gsap.registerPlugin(ScrollTrigger)

const slides = {
  slide1: Slide1,
  slide2: Info2,
  slide3: Info3,
  slide4: Slide4,
}

const alts = {
  slide1: "Primary analysis population graphic",
  slide2: "Intent-to-treat population graphic",
  slide3: "Reasons for participant discontinuation graphic",
  slide4: "Completer population graphic"
}

const FigureSlideshow = ({ figures, currentTopic }) => {
  useEffect(() => {
    ScrollTrigger.create({
      trigger: "#topicContainer",
      start: "top 18.5%",
      end: "bottom 75%",
      pin: ".pinContainer",
    })
  }, [])

  return (
    <div className={classNames([styles.figureSlideshow, "pinContainer"])}>
      <div className={styles.slideshowContent}>
        {figures.map((figure, index) => {
          return (
            <div
              key={index}
              className={classNames([styles.figureContainer], {
                [styles.show]: index === currentTopic,
              })}
            >
              <img
                alt={alts[figure.image]}
                src={slides[figure.image]}
                className={styles.figure}
              />
              {(figure.caption || figure.secondaryCaption) && (
                <div className={styles.captionContainer}>
                  {figure.caption && (
                    <p>
                      <strong>
                        {figure.caption.title}
                        {figure.caption.references?.length > 0 && (
                          <sup className={styles.references}>
                            {figure.caption.references.join(",")}
                          </sup>
                        )}
                      </strong>
                    </p>
                  )}
                  {figure.secondaryCaption && (
                    <p>
                      {figure.secondaryCaption.title}
                      {figure.secondaryCaption.references?.length > 0 && (
                        <sup className={styles.references}>
                          {figure.secondaryCaption.references.join(",")}
                        </sup>
                      )}
                    </p>
                  )}
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const Topic = ({ header, figure, topics, index, onLoad, setCurrentTopic, currentTopic }) => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    ScrollTrigger.create({
      trigger: index > 0 ? `#topicHeader${index}` : `#topicFigure${index}`,
      start: "top+=24px center",
      end: "bottom center",
      onEnter: () => {
        setCurrentTopic(index)
        setActive(true)
      },
      onLeaveBack: () => {
        setCurrentTopic(index > 0 ? index - 1 : 0)
        setActive(false)
      },
    })
  }, [index, setCurrentTopic])
  return (
    <div
      id={`topicFigure${index}`}
      className={classNames([styles.topic], {
        [styles.active]: index === currentTopic,
      })}
    >
      <h2 id={`topicHeader${index}`} className={styles.header}>
        {header}
      </h2>
      <div className={styles.mainTopicContainer}>
        {topics.map(({ topic, strong, footnote }, index) => {
          return (
            <p key={index} className={styles.topicText}>
              {strong && <strong>{`${strong} `}</strong>}
              {topic && topic}
              {footnote && <sup className={styles.footnote}>{footnote}</sup>}
            </p>
          )
        })}
      </div>
      <div className={styles.figureContainer}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <img
          onLoad={onLoad}
          alt=""
          src={slides[figure.image]}
          className={styles.figure}
        />
        {(figure.caption || figure.secondaryCaption) && (
          <div className={styles.captionContainer}>
            {figure.caption && (
              <p>
                <strong>
                  {figure.caption.title}
                  {figure.caption.references?.length > 0 && (
                    <sup className={styles.references}>
                      {figure.caption.references.join(",")}
                    </sup>
                  )}
                </strong>
              </p>
            )}
            {figure.secondaryCaption && (
              <p>
                {figure.secondaryCaption.title}
                {figure.secondaryCaption.references?.length > 0 && (
                  <sup className={styles.references}>
                    {figure.secondaryCaption.references.join(",")}
                  </sup>
                )}
              </p>
            )}
          </div>
        )}
      </div> 
    </div>
  )
}

const PalisadeStudyDesign = ({ topicList }) => {
  const listRef = useRef(null)
  const [topicsLoaded, setTopicsLoaded] = useState(0)
  const [progressMaxHeight, setProgressMaxHeight] = useState(0)
  const [progressTop, setProgressTop] = useState(0)
  const [progressOffset, setProgressOffset] = useState(0)
  const [progress, setProgress] = useState(0)
  const { width } = useWindowSize()
  const [currentTopic, setCurrentTopic] = useState(0)

  const onTopicImageLoad = () => {
    setTopicsLoaded(topicsLoaded + 1)
  }

  const footnotes = []

  useEffect(() => {
    ScrollTrigger.create({
      trigger: "#topicContainer",
      start: "top center",
      end: "bottom center",
      onUpdate: (self) => {
        // console.log("progress:", self.progress.toFixed(3))
        setProgress(self.progress.toFixed(3))
      },
    })
  }, [])

  useEffect(() => {
    if (topicsLoaded === topicList.length) {
      setProgressTop(listRef.current?.children[1].children[0].offsetHeight)
      setProgressMaxHeight(listRef.current?.offsetHeight)
      setProgressOffset(
        listRef.current?.lastChild.offsetHeight -
          listRef.current?.lastChild.children[0].offsetHeight - 10
      )
      ScrollTrigger.refresh()
    }
  }, [topicsLoaded, topicList])

  useEffect(() => {
    let clientHeight = (window.innerWidth <= 414) ? 24 : 44;
    setProgressTop(listRef.current?.children[1].children[0].offsetHeight)
    setProgressMaxHeight(listRef.current?.offsetHeight)
    setProgressOffset(
      listRef.current?.lastChild.offsetHeight -
        listRef.current?.lastChild.children[0].offsetHeight + clientHeight
    )
    ScrollTrigger.refresh()
  }, [width])

  return (
    <>
      <div className={styles.container}>
        <FigureSlideshow
          figures={topicList.map((topic) => topic.figure)}
          currentTopic={currentTopic}
        />
        <div
          id={"topicContainer"}
          ref={listRef}
          className={styles.topicContainer}
        >
          <div
            id={"progress"}
            className={styles.progress}
            style={{
              height: `${progressMaxHeight}px`,
            }}
          >
            <div
              className={styles.topMask}
              style={{
                height: `20px`,
              }}
            />
            <div
              className={styles.progressValue}
              style={{
                height: `${Math.floor(progress * 100)}%`,
              }}
            />
            <div
              className={styles.bottomMask}
              style={{
                height: `calc(${progressOffset}px - 1rem )`,
              }}
            />
          </div>
          {topicList.map((topic, index) => {
            return (
              <Topic
                setCurrentTopic={setCurrentTopic}
                key={index}
                {...topic}
                index={index}
                onLoad={onTopicImageLoad}
                currentTopic={currentTopic}
              ></Topic>
            )
          })}
        </div>
      </div>
      <ol className={styles.footnotes}>
        {footnotes.map((footnote, index) => {
          return (
            <li className={styles.footnoteRef} key={index}>
              <sup className={styles.symbol}>{FOOTNOTE_SYMBOLS[index]}</sup>
              {footnote}
            </li>
          )
        })}
      </ol>
    </>
  )
}

export default PalisadeStudyDesign
