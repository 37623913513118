import React from "react"
import { Container } from "react-bootstrap"

import classNames from "classnames"
import { FOOTNOTE_SYMBOLS } from "constants/Site"

import Chart1 from "images/palisade-chart.png"
import MobileChart1 from "images/palisade-card-g-mobile-chart-1.svg"
import Caption from "components/Caption/Caption"
import Legend from "images/palisade-card-g-desktop-legend.svg"
import MobileLegend from "images/palisade-card-g-mobile-legend.png"

import styles from "./PalisadeCardG.module.scss"

const PalisadeCardG = ({ supheader, header, topic, figure, caption, footnotes }) => {
  return (
    <Container fluid as={"section"} className={styles.cardContainer} id={"safety"}>
      <Container>
        <h3 className={styles.supheader}>
          {supheader.title}
          {supheader.footnotes.length > 0 && (
            <sup className={styles.references}>
              {supheader.footnotes.join(",")}
            </sup>
          )}
        </h3>
        <h2 className={styles.header}>
          {header.title}
          {header.references.length > 0 && (
            <sup className={styles.references}>
              {header.references.join(",")}
            </sup>
          )}
        </h2>
        <h3 className={styles.topicHeader}>
          {topic.title}
        </h3>
        <Caption>{caption}</Caption>
        <div className={styles.chartContainer}>
          <div className={styles.figuresContainer}>
            <div className={styles.figureContainer}>
              <h3 className={classNames([styles.figureTitle, styles.brand])}>
                {figure.header.title}<sup className={styles.references}>†</sup>
              </h3>
              <img className={styles.chart} alt="Maximum severity of symptoms comparison chart" src={Chart1} />
            </div>
          </div>
        </div>
        <ol className={styles.footnotes}>
          {footnotes.map((footnote, index) => {
            return (
              <li className={styles.footnoteRef} key={index}>
                <sup className={styles.symbol}>{FOOTNOTE_SYMBOLS[index]}</sup>
                {footnote}
              </li>
            )
          })}
        </ol>
      </Container>
    </Container>
  )
}

export default PalisadeCardG
