import React from "react"

const MobileEndStep = ({ classes, children }) => (
  <div className={classes}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="226"
      height="154"
      viewBox="0 0 226 154"
    >
      <path
        id="Path"
        d="M214.8,15l-83.5,45.8c-10,5.5-24.2,5.6-34.4,0.2L10.9,15C4.2,11.4,0.2,5.9,0.2,0L0,139.5
	c0,7.5,9.5,13.6,17,13.6h192.1c7.5,0,15.9-6.1,15.9-13.6V2.7C224.1,7.5,220.5,11.9,214.8,15z"
      />
    </svg>
    {children}
  </div>
)

export default MobileEndStep
