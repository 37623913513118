import React from "react"
import { Container } from "react-bootstrap"

import { FOOTNOTE_SYMBOLS } from "constants/Site"

import TrialArrows from "./TrialArrows"

import styles from "./PalisadeCardA.module.scss"

const PalisadeCardA = ({ header, trialArrows, footnotes, footer }) => {
  return (
    <Container as={"section"} id={"design"} className={styles.cardContainer}>
      <h2 className={styles.header}>
        {header.title}
        {header.references.length > 0 && (
          <sup className={styles.references}>{header.references.join(",")}</sup>
        )}
      </h2>
      <TrialArrows steps={trialArrows} />
      {footnotes.map((footnote, index) => (
        <p key={footnote} className={styles.footnote}>
          <span className={styles.symbol}>{FOOTNOTE_SYMBOLS[index]}</span>
          {footnote}
        </p>
      ))}
      <p className={styles.footer}>{footer}</p>
    </Container>
  )
}

export default PalisadeCardA
