import React from "react"
import { Container } from "react-bootstrap"

import Caption from "components/Caption/Caption"

import styles from "./PalisadeCardB.module.scss"

const PalisadeCardB = ({ header, topicA, topicList, topicB, caption }) => {
  return (
    <Container fluid as={"section"} className={styles.cardContainer}>
      <Container>
        <h2 className={styles.header}>
          {header.title}
          {header.references.length > 0 && (
            <sup className={styles.references}>
              {header.references.join(",")}
            </sup>
          )}
        </h2>
        <h3 className={styles.topicHeader}>{topicA}</h3>
        <dl className={styles.topicList}>
          <dt className={styles.topicListHeader}>{topicList.header}</dt>
          {topicList.topics.map((topic) => (
            <dd className={styles.topicListTopic} key={topic}>
              <span className={styles.topicCopy}>{topic}</span>
            </dd>
          ))}
        </dl>
        <p className={styles.topic}>
          <span className={styles.bold}>{`${topicB.header} `}</span>
          {topicB.topic}
        </p>
        <Caption>{caption}</Caption>
        {/*<p className={styles.footer}>
          PALISADE = <strong>P</strong>eanut <strong>AL</strong>lergy Oral{" "}
          <strong>I</strong>mmunotherapy <strong>S</strong>tudy of{" "}
          <strong>A</strong>R101 for <strong>DE</strong>sensitization.
          </p>*/}
      </Container>
    </Container>
  )
}

export default PalisadeCardB
