import React from "react"
import { Container } from "react-bootstrap"

import { FOOTNOTE_SYMBOLS } from "constants/Site"

import PalisadeCardEDesktopChart from "images/palisade-card-e-desktop-chart.svg"
import PalisadeCardEMobileChart from "images/palisade-card-e-mobile-chart.png"

import styles from "./PalisadeCardE.module.scss"

const PalisadeCardE = ({ supheader, header, topic, topicList, footnotes }) => {
  return (
    <Container as={"section"} className={styles.cardContainer}>
      <h3 className={styles.supheader}>{supheader.title}</h3>
      <h3 className={styles.topicHeader}>{topic.title}</h3>
      <p className={styles.suptitle}>{topic.suptitle}</p>
      <dl className={styles.topicList}>
          <dt className={styles.topicListHeader}>{topicList.header}</dt>
          {topicList.topics.map((topic) => (
            <dd className={styles.topicListTopic} key={topic}>
              <span className={styles.topicCopy}>{topic}</span>
            </dd>
          ))}
        </dl>
      
    </Container>
  )
}

export default PalisadeCardE
