import React from "react"

const StartStep = ({ classes, children }) => (
  <div className={classes}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="218"
      height="115"
      viewBox="0 0 218 115"
      className={classes}
    >
      <path
        id="Path"
        d="M215.2,47.6L194.8,4.9C194,2.8,192.3,1,190.2,0H8.9C6.5,0,4.3,0.9,2.6,2.6S0,6.5,0,8.9v97
	c0,2.4,0.9,4.6,2.6,6.3s3.9,2.6,6.3,2.6h170.7l8.5-0.1c2.6,0,5.1-2,6.7-5.5l20.5-43.9C217.8,59.6,217.7,53.2,215.2,47.6z"
      />
    </svg>
    {children}
  </div>
)

export default StartStep
