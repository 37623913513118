import React from "react"

import StartStep from "assets/svg/start-step"
import MobileStartStep from "assets/svg/mobile-start-step"
import MiddleStep from "assets/svg/middle-step"
import MobileMiddleStep from "assets/svg/mobile-middle-step"
import EndStep from "assets/svg/end-step"
import MobileEndStep from "assets/svg/mobile-end-step"

import styles from "./PalisadeCardA.module.scss"

const TrialArrows = ({ steps }) => {
  const renderFigureText = ({ figure, footnotes, duration }) => {
    return (
      <div className={styles.figure}>
        <span className={styles.text}>
          {figure}
          {footnotes?.length > 0 && (
            <sup className={styles.footnotes}>{footnotes.join("")}</sup>
          )}
        </span>
        {duration && <span className={styles.subtext}>{`(${duration})`}</span>}
      </div>
    )
  }

  return (
    <section className={styles.trialArrows}>
      {steps.map(({ figure, footnotes, duration, caption }, index) => (
        <div key={index} className={styles.step}>
          {index === 0 && (
            <>
              <StartStep classes={styles.stepBackground}>
                {renderFigureText({ figure, footnotes, duration })}
              </StartStep>
              <MobileStartStep classes={styles.mobileStepBackground}>
                {renderFigureText({ figure, footnotes, duration })}
              </MobileStartStep>
            </>
          )}
          {index > 0 && index !== steps.length - 1 && (
            <>
              <MiddleStep classes={styles.stepBackground}>
                {renderFigureText({ figure, footnotes, duration })}
              </MiddleStep>
              <MobileMiddleStep classes={styles.mobileStepBackground}>
                {renderFigureText({ figure, footnotes, duration })}
              </MobileMiddleStep>
            </>
          )}
          {index === steps.length - 1 && (
            <>
              <EndStep classes={styles.stepBackground}>
                {renderFigureText({ figure, footnotes, duration })}
              </EndStep>
              <MobileEndStep classes={styles.mobileStepBackground}>
                {renderFigureText({ figure, footnotes, duration })}
              </MobileEndStep>
            </>
          )}

          <p className={styles.caption}>{caption}</p>
        </div>
      ))}
    </section>
  )
}

export default TrialArrows
