import React from "react"

const MiddleStep = ({ classes, children }) => (
  <div className={classes}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="218"
      height="115"
      viewBox="0 0 218 115"
    >
      <path
        id="Path"
        d="M195.2,5c-0.9-2.2-2.5-3.9-4.6-4.9h-2.2H2.4c2.1,1,3.7,2.7,4.6,4.9l20.3,42.8
	c2.4,5.6,2.5,12,0.1,17.6L7,109.5c-0.4,0.8-0.8,1.6-1.4,2.3L0.4,115H0.3H0.2v0.1h188.2h0.1c2.6,0,5.1-2,6.7-5.5l20.4-44.1
	c2.4-5.6,2.4-12-0.1-17.7L195.2,5z"
      />
    </svg>
    {children}
  </div>
)

export default MiddleStep
