import React from "react"
import { Container } from "react-bootstrap"

import PalisadeStudyDesign from "./PalisadeStudyDesign"

import styles from "./PalisadeCardC.module.scss"

const PalisadeCardC = ({ header, topicList }) => {
  return (
    <Container fluid as={"section"} className={styles.cardContainer}>
      <Container>
        <h2 className={styles.header}>
          {header.title}
          {header.references.length > 0 && (
            <sup className={styles.references}>
              {header.references.join(",")}
            </sup>
          )}
        </h2>
        <PalisadeStudyDesign topicList={topicList} />
      </Container>
    </Container>
  )
}

export default PalisadeCardC
