import React from "react"

const EndStep = ({ classes, children }) => (
  <div className={classes}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="218"
      height="115"
      viewBox="0 0 218 115"
      className={classes}
    >
      <path
        id="Path"
        d="M209.1,0H3.1c2.1,1,3.7,2.8,4.6,4.9L28,47.8c2.4,5.6,2.5,12,0.1,17.6L7.7,109.5
	c-0.4,0.8-0.8,1.6-1.3,2.3L1.1,115H0.9v0.1h179.4h8.9c0.1,0,0.3,0,0.4,0H210c4.5-0.5,8-4.3,8-8.8V8.9C218,4,214,0,209.1,0z"
      />
    </svg>
    {children}
  </div>
)

export default EndStep
