import React from "react"

const MobileStartStep = ({ classes, children }) => (
  <div className={classes}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="226"
      height="158"
      viewBox="0 0 226 158"
    >
      <path
        id="Path"
        d="M225,88.1V32.5l0,0V10.8c0-6,0.7-10.8-6.8-10.8H6.8C3,0,0,2.4,0,5.4v88.2c0.4,5.6,4.3,10.7,10.7,14.2
	l86.1,45.9c10.2,5.4,24.4,5.4,34.4-0.1l83.6-45.8c5.6-3.1,9.2-7.5,10.2-12.3L225,88.1L225,88.1z"
      />
    </svg>
    {children}
  </div>
)

export default MobileStartStep
