import React, { useState } from "react"

import classNames from "classnames"

import styles from "./PalisadeTabs.module.scss"

const PalisadeTabs = ({ content, brand, separator, opponent, subheader }) => {
  const [currentTab, setCurrentTab] = useState(1)
  console.log('content ', content);
  const current = content[currentTab]

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h4 className={styles.title}>
          {`${brand} ${separator} `}
          <span className={styles.opponent}>{opponent}</span>
        </h4>
        <h5 className={styles.subheader}>{subheader}</h5>
        <div className={styles.figureContainer}>
          <div className={styles.figure}>
            <span className={styles.value}>
              {current.brand.figure.value}
              <sub className={currentTab === 2 && styles.lastSub}>{current.brand.figure.units}</sub>
            </span>
            <span
              className={styles.sample}
            >{`(n=${current.brand.figure.count}/${current.brand.figure.size})`}</span>
          </div>
          <div className={styles.figureAlt}>
            <span className={styles.value}>
              {current.opponent.figure.value}
              <sub>{current.opponent.figure.units}</sub>
            </span>
            <span
              className={styles.sample}
            >{`(n=${current.opponent.figure.count}/${current.opponent.figure.size}; p<${current.opponent.figure.prob})`}</span>
          </div>
        </div>
          <p className={current.disclaimer ? '' : styles.empty}>
            {current.disclaimer}
            {current.references && (
            <sup className={styles.references}>
              {current.references.join(",")}
            </sup>
          )}
          </p>
      </div>
      <div className={styles.navigation}>
        {content.map(({ menuLabel }, index) => {
          return (
            <button
              onClick={() => setCurrentTab(index)}
              className={classNames([styles.button], {
                [styles.active]: index === currentTab,
              })}
              key={index}
              dangerouslySetInnerHTML={{ __html: menuLabel}}
            >
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default PalisadeTabs
