export const usePalisadeData = ({ referencesHeader }) => {
  const HEADER = {
    title:
      "PALISADE study: Phase 3 clinical trial studied PALFORZIA",
    references: [1, 2],
  }

  const TABS = [
    {
      label: "Study Design",
      href: "#design",
    },
    {
      label: "Efficacy",
      href: "#efficacy",
    },
    {
      label: "Safety",
      href: "#safety",
    },
  ]

  const CARD_A = {
    header: {
      title:
        "Duration of trial was 12 months with food challenges at entry and exit",
      references: [1, 3],
    },
    trialArrows: [
      {
        figure: "Entry\nDBPCFC",
        footnotes: ["*"],
        caption: "In-office visit with gradual increasing peanut protein dose levels ranging from 1 mg to 100 mg",
      },
      {
        figure: "Initial Dose\nEscalation",
        duration: "1 day",
        caption:
          "In-office visit with dose gradually increasing from 0.5 mg to 6 mg",
      },
      {
        figure: "Up-Dosing",
        duration: "~6 months",
        caption:
          "Daily home dosing with in-office dose escalation every ~2 weeks (11 levels) until 300 mg is achieved",
      },
      {
        figure: "Maintenance\nDosing",
        duration: "6 months",
        caption: "Daily 300-mg dose taken at home",
      },
      {
        figure: "Exit\nDBPCFC",
        caption:
          "Primary endpoint and other study endpoints assessed; in-office visit to assess desensitization; peanut protein levels ranging from 3 mg to 1000 mg",
      },
    ],
    footnotes: [
      "Entry food challenge was required only in the PALISADE study for screening.  It also provided a baseline comparator for results at treatment end.",
    ],
    footer: "DBPCFC = double-blind, placebo-controlled food challenge.",
  }

  const CARD_B = {
    header: {
      title: "PALFORZIA was studied in highly peanut-allergic participants",
      references: [1, 3],
    },
    topicA:
      "All study participants had an allergic reaction at 100 mg or less of peanut protein at the entry food challenge.",
    topicList: {
      header: "Key inclusion criteria:",
      topics: [
        "Clinical history of peanut allergy",
        "pslgE of ≥0.35 kUA/L and/or mean peanut skin-prick test wheal diameter ≥3 mm larger than the negative control",
      ],
    },
    topicB: {
      header: "Key exclusion criteria:",
      topic:
        "Uncontrolled asthma, history of eosinophilic esophagitis, other eosinophilic gastrointestinal disease, or severe anaphylaxis within 60 days of screening",
    },
    caption:
      "72% of participants reported a history of peanut-related anaphylaxis prior to enrolling in the study."/*,
    footer:
      "PALISADE = Peanut ALlergy Oral Immunotherapy Study of AR101 for DEsensitization in Children and Adults.",*/
  }

  const CARD_C = {
    header: {
      title:
        "PALISADE is the largest randomized, double-blind, placebo-controlled clinical trial for peanut allergy",
      references: [1, 2],
    },
    topicList: [
      {
        header: "Primary analysis population",
        topics: [{ topic: "496 participants aged 4 through 17 years" }],
        figure: {
          caption: {
            title:
              "Primary efficacy analysis was in participants aged 4 through 17 years",
          },
          image: "slide1",
        },
      },
      {
        header: "Intent-to-treat population",
        topics: [
          { strong: "3:1 randomization ratio", footnote: ["*"] },
          { topic: "372 participants received PALFORZIA" },
          { topic: "124 participants received placebo" },
        ],
        figure: {
          secondaryCaption: {
            title:
              "*Three participants were randomized in error; a total of 496 participants were exposed to ≥1 dose of study product. For placebo, 125 participants should have received placebo, but 1 did not due to withdrawal of consent. For PALFORZIA, 374 should have received treatment, but 2 did not (1 withdrawal of consent and 1 randomization error).",
          },
          image: "slide2",
        },
      },
      {
        header: "Reasons for participant discontinuation",
        topics: [
          {
            strong: "Adverse event:",
            topic: "43 with PALFORZIA vs 3 with placebo",
          },
          {
            strong: "Withdrew consent:",
            topic: "31 with PALFORZIA vs 6 with placebo",
          },
          {
            strong: "Removed by investigator:",
            topic: "1 with PALFORZIA vs 0 with placebo",
          },
          {
            strong: "Other:",
            topic: "5 with PALFORZIA vs 1 with placebo",
          },
        ],
        figure: {
          image: "slide3",
        },
      },
      {
        header: "Completer population",
        topics: [
          {
            topic:
              "296 participants treated with PALFORZIA were assessed for levels of desensitization",
          },
          {
            topic:
              "116 participants treated with placebo were assessed for levels of desensitization",
          },
        ],
        figure: {
          image: "slide4",
        },
      },
    ],
  }

  const CARD_D = {
    supheader: {
      title: "Intent-to-treat Population",
      footnotes: ["*"],
    },
    newHeader: {
      title: "Proven to reduce the severity of allergic reactions to peanut",
      references: [1],
    },
    header: {
      title: "PALFORZIA met its primary endpoint in PALISADE",
      references: [1, 2],
    },
    topic: {
      header: {
        title: "PALFORZIA met its primary endpoint in PALISADE",
        references: [1, 2],
      },
      topic: {
        label: "Primary efficacy endpoint: ",
        body:
          "The percentage of participants receiving at least 1 study dose who tolerated 600 mg of peanut protein as a single dose with no more than mild allergic symptoms in the exit DBPCFC. The DBPCFC at the end of the Maintenance Phase was used to approximate an accidental exposure to peanut.",
      },
    },
    tabs: {
      brand: "Palforzia",
      separator: "vs",
      opponent: "Placebo",
      subheader: "Desensitization response rate, single dose",
      content: [
        {
          menuLabel: "300 mg\n<span>SECONDARY ENDPOINT</span>",
          disclaimer: "300 mg daily dose helps maintain the threshold of desensitization",
          brand: {
            figure: {
              value: 77,
              units: "%",
              count: 285,
              size: 372,
              prob: "0.001",
            },
          },
          opponent: {
            figure: {
              value: 8,
              units: "%",
              count: 10,
              size: 124,
              prob: "0.0001",
            },
          },
        },
        {
          menuLabel: "600 mg\n<span>PRIMARY ENDPOINT</span>",
          brand: {
            figure: {
              value: 67,
              units: "%",
              count: 250,
              size: 372,
              prob: "0.001",
            },
          },
          opponent: {
            figure: {
              value: 4,
              units: "%",
              count: 5,
              size: 124,
              prob: "0.0001",
            },
          },
        },
        {
          menuLabel: "1000 mg\n<span>SECONDARY ENDPOINT</span>",
          references: [],
          brand: {
            figure: {
              value: 50,
              units: "%",
              count: 187,
              size: 372,
              prob: "0.001",
            },
          },
          opponent: {
            figure: {
              value: 2,
              units: "%",
              count: 3,
              size: 124,
              prob: "0.0001",
            },
          },
        },
      ],
    },
    tabDisclaimer: "1000 mg dose is 8x the median amount of peanut protein that triggered an accidental allergic reaction (125 mg) in the observational MIRABEL study. This median amount was studied outside of the PALFORZIA clinical trials.",
    caption:
      "The maintenance dose during the study was 300 mg daily. Many participants who received PALFORZIA were able to tolerate more than 300 mg of peanut protein in the exit DBPCFC.",
    footnotes: [
      "All study participants who received at least 1 dose of treatment, including those who discontinued the study.",
    ],
  }

  const CARD_E = {
    supheader: {
      title: "Completer Population",
    },
    header: {
      title: "Proven to reduce the severity of allergic reactions to peanut",
      references: [1],
    },
    topic: {
      suptitle: 
        "During the exit dbpcfc after 12 months in the clinical study",
      title:
        "The completer population consisted of participants aged 4 through 17 years in the ITT population who stayed on treatment and had an evaluable exit DBPCFC (296 PALFORZIA, 116 placebo)",
    },
    topicList: {
      topics: [
        "96.3% of participants who received PALFORZIA were able to tolerate 300 mg of peanut protein with no more than mild symptoms (vs 8.6% for placebo)",
        "84.5% of participants who received PALFORZIA were able to tolerate 600 mg of peanut protein with no more than mild symptoms (vs 4.3% for placebo)",
        "63.2% of participants who received PALFORZIA were able to tolerate 1000 mg of peanut protein with no more than mild symptoms (vs 2.6% for placebo)",
      ],
    },
  }

  const CARD_F = {
    header: {
      title: "Tolerating 300 mg = clinically meaningful desensitization",
      references: [5],
    },
    figure: {
      header: {
        title: "In a quantitative exposure model:",
      },
      topic: {
        title:
          "In the same study, an individual with a baseline threshold of 30-mg peanut protein who increases the threshold to 300-mg peanut protein after immunotherapy decreases the percentage of a predicted allergic reaction from 1.9% to 0.004%, or by 99.8%.<sup>5</sup>",
      },
    },
    footnotes: [
      "Results from a quantitative risk assessment that matched modeled exposure to peanut protein with individual threshold levels.",
    ],
  }

  const CARD_G = {
    supheader: {
      title: "Intent-to-treat Population",
      footnotes: ["*"],
    },
    header: {
      title: "Ready for less-severe symptoms",
      references: [1],
    },
    topic: {
      title:
        "Comparison of the maximum severity of symptoms at any challenge dose of peanut protein during the exit DBPCFC",
    },
    figure: {
      header: {
        title: "Maximum severity of symptoms",
      },
      brand: "Palforzia",
      opponent: "Placebo",
    },
    caption:
      "5% of participants treated with PALFORZIA experienced severe symptoms compared with 10.5% on placebo during the exit DBPCFC.",
    footnotes: [
      "All study participants who received at least 1 dose of treatment, including those who discontinued the study.",
      "Participants without an exit DBPCFC were assigned the maximum severity during the screening DBPCFC, which equates to no change from screening.", 
      "P-value < 0.0001; symptom severity was assigned with equally spaced scores (e.g., 0, 1, 2, and 3 for none, mild, moderate, and severe, respectively), and the difference of mean scores between the two treatment arms was tested using the Cochran-Mantel-Haenszel statistic stratified by geographic region (North America, Europe).",
      "Includes severe symptoms and life-threatening or fatal reactions. No participants had symptoms considered life threatening or fatal.",
    ],
    
  }

  const CARD_H = {
    supheader: {
      title: "Completer Population",
    },
    header: {
      title: "Ready for fewer reactions",
      references: [1, 6],
    },
    topic: {
      title:
        "During controlled simulations of accidental peanut exposure after 12 months in the clinical study (exit food challenge).",
      references: [1],
    },
  }

  return {
    header: { ...HEADER },
    tabs: [...TABS],
    cardA: { ...CARD_A },
    cardB: { ...CARD_B },
    cardC: { ...CARD_C },
    cardD: { ...CARD_D },
    cardE: { ...CARD_E },
    cardF: { ...CARD_F },
    cardG: { ...CARD_G },
    cardH: { ...CARD_H },
    referencesHeader: referencesHeader.title,
  }
}
