import React from "react"
import { Container } from "react-bootstrap"

import { FOOTNOTE_SYMBOLS } from "constants/Site"

import Caption from "components/Caption/Caption"
import PalisadeTabs from "./PalisadeTabs"

import styles from "./PalisadeCardD.module.scss"

const PalisadeCardD = ({
  header,
  newHeader,
  supheader,
  topic,
  caption,
  tabDisclaimer,
  footnotes,
  tabs,
}) => {
  return (
    <Container
      id={"efficacy"}
      fluid
      as={"section"}
      className={styles.cardContainer}
    >
      <Container>
        <h2 className={styles.header}>
          {newHeader.title}
          {newHeader.references.length > 0 && (
            <sup className={styles.references}>
              {newHeader.references.join(",")}
            </sup>
          )}
        </h2>
        <h3 className={styles.supheader}>
          {supheader.title}
          {supheader.footnotes.length > 0 && (
            <sup className={styles.references}>
              {supheader.footnotes.join(",")}
            </sup>
          )}
        </h3>
        <h2 className={styles.header}>
          {header.title}
          {header.references.length > 0 && (
            <sup className={styles.references}>
              {header.references.join(",")}
            </sup>
          )}
          </h2>
        <div className={styles.topicContainer}>
          <p className={styles.topic}>
            <strong>{`${topic.topic.label} `}</strong>
            {topic.topic.body}
          </p>
        </div>
        <PalisadeTabs {...tabs} />
        <p className={styles.tabDisclaimer}>{tabDisclaimer}<sup>4</sup></p>
        <Caption>{caption}</Caption>
        <ol className={styles.footnotes}>
          {footnotes.map((footnote, index) => {
            return (
              <li className={styles.footnoteRef} key={index}>
                <sup className={styles.symbol}>{FOOTNOTE_SYMBOLS[index]}</sup>
                {footnote}
              </li>
            )
          })}
        </ol>
      </Container>
    </Container>
  )
}

export default PalisadeCardD
